import request from '@/utils/request'

// 查询薪资发放记录列表
export function listSalaryRecord(query) {
  return request({
    url: '/iot/salaryRecord/list',
    method: 'get',
    params: query
  })
}

// 查询薪资发放记录详细
export function getSalaryRecord(id) {
  return request({
    url: '/iot/salaryRecord/' + id,
    method: 'get'
  })
}

// 新增薪资发放记录
export function addSalaryRecord(data) {
  return request({
    url: '/iot/salaryRecord',
    method: 'post',
    data: data
  })
}

// 修改薪资发放记录
export function updateSalaryRecord(data) {
  return request({
    url: '/iot/salaryRecord',
    method: 'put',
    data: data
  })
}

// 删除薪资发放记录
export function delSalaryRecord(id) {
  return request({
    url: '/iot/salaryRecord/' + id,
    method: 'delete'
  })
}

// 导出薪资发放记录
export function exportSalaryRecord(query) {
  return request({
    url: '/iot/salaryRecord/export',
    method: 'get',
    params: query
  })
}

// 下载薪资发放记录导入模板
export function importTemplate() {
  return request({
    url: '/iot/salaryRecord/importTemplate',
    method: 'get'
  })
}

// 导入薪资发放记录
export function importData(data) {
  return request({
    url: '/iot/salaryRecord/importData',
    method: 'post',
    data: data
  })
}
// 根据选定月份,删除薪资发放记录
export function removeBySalaryDate(query) {
  return request({
    url: '/iot/salaryRecord/removeBySalaryDate',
    method: 'get',
    params: query
  })
}
